import axios from 'axios'
import store from '../store'

export default {
  methods: {
    async getCartById(cartId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/cart/${cartId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getAllActiveChannelCarts(channelId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/cart/channel/${channelId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getAllActiveLocationCarts(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/cart/location/${locationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveCartSpace(cartKey, space) {
      let criteria = {
        CartKey: cartKey,
        Id: space.Id,
        SpaceId: space.SpaceId,
        SettingId: space.SettingId,
        Seats: space.Seats,
        StartDate: space.StartDate,
        StartMinutes: space.StartMinutes,
        EndDate: space.EndDate,
        EndMinutes: space.EndMinutes,
        CurrencyId: space.CurrencyId,
        PricePerSeat: space.PricePerSeat,
        PriceTotalExclTax: space.PriceTotal,
        TaxId: space.TaxId,
        Crc: space.Crc
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/cart/space`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteCartSpace(cartKey, id) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/v1/cart/${cartKey}/space/${id}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveCartOption(
      cartKey,
      optionId,
      categoryId,
      amount,
      currencyId,
      price,
      priceTotal,
      crc,
      taxId
    ) {
      let criteria = {
        CartKey: cartKey,
        OptionId: optionId,
        CategoryId: categoryId,
        Amount: amount,
        CurrencyId: currencyId,
        PricePerItem: price,
        PriceTotal: priceTotal,
        Crc: crc,
        TaxId: taxId
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/cart/option`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteCartOption(cartKey, optionId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/cart/${cartKey}/option/${optionId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async finalizeCart(cartKey) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/cart/finalize/${cartKey}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async extendCartTimeout(cartKey, minutes) {
      let criteria = {
        Minutes: minutes
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/cart/${cartKey}/extend`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    }
  }
}
