<template>
  <div v-if="cartsPageState">
    <ui-component-drawer
      v-if="showSideDrawer"
      :drawerTitle="drawerTitle"
      :showDrawer="showSideDrawer"
      :onClickClose="hideDrawer"
      position="right"
    >
      <template v-slot:content>
        <cart-summary
          v-if="showCartSummary"
          :cartId="itemId"
          :locationId="locationId"
          :cart="item"
          @goToCart="goToCart"
        ></cart-summary>
      </template>
    </ui-component-drawer>

    <div v-for="(cart, index) in cartsPageState.carts" :key="'cart' + index">
      <cart-item
        :cart="cart"
        :key="'c' + timestamp"
        @cartSummary="setShowCartSummary"
        @cartDetail="goToCart"
        @cartDeleted="deleteCart"
        @cartExtended="updateCart"
      ></cart-item>
    </div>

    <div v-if="!isLoading && cartsPageState.carts.length === 0">
      <article class="message is-link">
        <div class="message-body">No carts or drafts found.</div>
      </article>

      <div class="has-text-centered">
        <a @click="goToCreateReservation" class="button is-large is-primary">
          <span class="icon">
            <font-awesome-icon :icon="['fas', 'plus']" />
          </span>
          <span>New reservation</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import cartProvider from '@/providers/cart'

const CartItem = () => import('@/components/Carts/CartItem')
const CartSummary = () => import('@/components/Carts/CartSummary')

export default {
  components: {
    'cart-item': CartItem,
    'cart-summary': CartSummary,
  },

  data() {
    return {
      drawerTitle: '',
      isLoading: false,
      item: null,
      itemId: -1,
      locationId: Number(this.$route.params.locationId),
      showCartSummary: false,
      showSideDrawer: false,
      timestamp: new Date().getTime(),
    }
  },

  computed: {
    ...mapState('cartStore', ['cartsPageState']),
    ...mapState('channelStore', ['channel']),
  },

  created() {
    this.getAllActiveChannelCarts()
  },

  mounted() {
    let self = this

    self.$cartHub.connection.on(
      self.$cartHub.listeners.cartCreated,
      (response) => {
        ////console.log('Cart created received')
        self.addCart(JSON.parse(response))
      }
    )

    self.$cartHub.connection.on(
      self.$cartHub.listeners.cartUpdated,
      (response) => {
        ////console.log('Cart update received')
        self.updateCart(JSON.parse(response))
      }
    )

    self.$cartHub.connection.on(
      self.$cartHub.listeners.cartDeleted,
      (response) => {
        ////console.log('Cart delete received')
        self.deleteCart(JSON.parse(response))
      }
    )
  },

  beforeDestroy() {
    let self = this

    /**
     * Leave group
     */
    self.$cartHub.leaveLocationCartsGroup(self.locationId)
  },

  methods: {
    ...mapMutations('cartStore', ['setCartsPageState']),

    getAllActiveChannelCarts() {
      let self = this

      self.$cartHub.joinChannelCartsGroup(self.channel.Id)

      if (!self.cartsPageState.reachedEnd) {
        self.isLoading = true

        cartProvider.methods
          .getAllActiveChannelCarts(self.channel.Id)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.MorePages === 0) {
                self.cartsPageState.reachedEnd = true
              }

              self.cartsPageState.morePages = 0 //response.data.MorePages
              self.cartsPageState.carts = response.data //.Results

              self.setCartsPageState(self.cartsPageState)
            }
          })
          .finally(() => {
            self.isLoading = false
          })
      }
    },

    addCart(cart) {
      let self = this

      self.cartsPageState.carts.push(cart)
      self.setCartsPageState(self.cartsPageState)

      self.timestamp = new Date().getTime()
    },

    updateCart(cart) {
      let self = this

      let carts = self.cartsPageState.carts
      let cartIndex = carts.findIndex((c) => c.Id === cart.Id)
      if (cartIndex > -1) {
        carts[cartIndex] = cart

        self.cartsPageState.carts = carts

        self.setCartsPageState(self.cartsPageState)
      }

      self.timestamp = new Date().getTime()
    },

    deleteCart(cart) {
      let self = this

      let carts = self.cartsPageState.carts
      let cartIndex = carts.findIndex((c) => c.Id === cart.Id)
      if (cartIndex > -1) {
        carts.splice(cartIndex, 1)

        self.cartsPageState.carts = carts
        self.setCartsPageState(self.cartsPageState)
      }

      self.timestamp = new Date().getTime()
    },

    setShowCartSummary(cart) {
      this.showSideDrawer = true
      this.drawerTitle = 'Cart details'
      this.itemId = cart.Id
      this.item = cart
      this.setVisibleSideBarItem('cartSummary')
    },

    setVisibleSideBarItem(item) {
      this.showCartSummary = item === 'cartSummary'
    },

    hideDrawer() {
      this.showSideDrawer = false
      this.itemId = -1
      this.item = null
      this.setVisibleSideBarItem('')
    },

    goToCart(cartId) {
      this.hideDrawer()

      setTimeout(() => {
        this.$router.push({
          name: `carts-detail`,
          params: { cartId: cartId },
        })
      }, 500)
    },

    goToCreateReservation() {
      this.$router.push({ name: `reservations-create` })
    },
  },
}
</script>
